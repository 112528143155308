import {getSocialProfileIcon, isIntersecting} from "@/utils/functions";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name: "neo-dropdown-analysis",
    components: {
        "font-awesome-icon": FontAwesomeIcon,
    },
    props: ["platform", "label", "values", "width", "height", "bgColor", "borderColor", "margin", "value", "icon", "searcheable", "counts", "placeholder", "borderFull", "update"],
    data() {
        return {
            open: false,
            search: "",
            selectedItem: null,
        };
    },
    watch: {
        value() {
            this.search = this.value ? this.value : "";
        },
    },
    computed: {
        filteredList() {
            let data = [];
            let platform = this.platform;
            let dataItem = null;
            this.values.forEach((item) => {
                dataItem = data.find((dataItem) => {
                    return dataItem.value === item.value;
                });
                if (dataItem) {
                    if (item.platform === platform) dataItem["visited"] = item["visited"];
                } else {
                    data.push(item);
                    if (item.platform !== platform && item !== "divider") data[data.length - 1].visited = false;
                }
            });
            return data.filter((item) => (item && item.value ? item.value.toString().toLowerCase().startsWith(this.search.toLowerCase()) : item ? item : ""));
        },
    },
    mounted() {
        if (this.placeholder) return;
        this.search = this.value ? this.value : "";
        window.addEventListener("click", ({x, y}) => {
            this.$parent.$children["neo-dropdown-analysis"];
        });
    },
    methods: {
        setCustomInput() {
            this.selectedItem = null;
            this.$emit(
                "setInput",
                this.selectedItem || {
                    value: this.search,
                }
            );
        },
        onSelect(item) {
            this.open = false;
            this.search = item.value;
            this.selectedItem = item;
            this.$emit("input", item.value);
        },
        onToggle() {
            this.open = !this.open;
            if (open) this.$refs.input?.focus();
            this.open ? (this.search = "") : !this.open && !this.value ? (this.search = "") : (this.search = this.value);
        },
    },
};
