import { render, staticRenderFns } from "./multiselect-template.html?vue&type=template&id=1212498b&scoped=true&"
import script from "./multiselect-template.js?vue&type=script&lang=js&"
export * from "./multiselect-template.js?vue&type=script&lang=js&"
import style0 from "./multiselect-template.scss?vue&type=style&index=0&id=1212498b&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1212498b",
  null
  
)

export default component.exports